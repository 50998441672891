import * as React from "react"

import { Button } from "@components/Button"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Arrow } from "@components/UI"
import { usePageSetup } from "@components/usePageSetup"
import { FeaturedWithDescription } from "@components/Work"
import BrandingMessaging from "@svg/what-we-do/branding-messaging.svg"
import EmployeeEngagement from "@svg/what-we-do/employee-engagement.svg"
import SocialGood from "@svg/what-we-do/social-good.svg"
import StrategyMarketing from "@svg/what-we-do/strategy-marketing.svg"
import { splitText } from "@utils"
import { Link } from "gatsby"

const WhatWeDoPage = () => {
  const { themeColor } = usePageSetup({ themeColor: "bg-purple-medium" })
  const themeColorBefore = "before:bg-purple-medium"

  return (
    <Layout>
      <Seo title="What We Do" description="" />
      <section className={themeColor}>
        <Hero className="min-h-700px pb-clamp-33-76 pt-clamp-16-44">
          <Hero.Title>
            <div className="grid-cols-10 xl:grid">
              <div className="col-span-8 xl:pr-20">
                <h1
                  data-aos="stagger"
                  className="text-headline"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-gray-darkest": "We create meaningful stories",
                      "text-white": "that build stronger businesses.",
                    }),
                  }}
                />
              </div>
            </div>
          </Hero.Title>

          <Hero.Message>
            <p>
              Many of the stories we tell seamlessly integrate our four core disciplines, allowing us to be a true
              creative and strategic partner who can bring big ideas to life across your most critical B2B, B2C, and
              talent touchpoints.
            </p>
          </Hero.Message>
        </Hero>
      </section>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />

      <section>
        <div className="relative overflow-hidden">
          <div className="container relative">
            <div
              className={`z-10 aspect-w-667 aspect-h-238 lg:aspect-w-1436 lg:aspect-h-120 extend ${themeColorBefore} after:bg-purple`}
            ></div>
            <div className="relative w-full lg:pt-20 pb-clamp-16-80 lg:pb-24 extend after:bg-purple">
              <div className="relative z-10 w-8/12 mx-auto lg:w-10/12">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-y-12 xl:grid-cols-4">
                  <div className="flex w-full md:block md:w-57">
                    <div className="w-36 md:w-auto">
                      <div className="w-19 md:h-40 md:w-25">
                        <BrandingMessaging />
                      </div>
                    </div>
                    <div>
                      <h2 className="font-bold leading-tight text-white text-clamp-26-32">Branding &amp; messaging</h2>
                      <div className="mt-clamp-5-8">
                        <Button link={`/b2b-branding`} className="text-white">
                          Learn more
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full md:block md:w-57">
                    <div className="w-36 md:w-auto">
                      <div className="md:h-40 w-21 md:w-34">
                        <EmployeeEngagement />
                      </div>
                    </div>
                    <div>
                      <h2 className="font-bold leading-tight text-white text-clamp-26-32">Employee engagement</h2>
                      <div className="mt-clamp-5-8">
                        <Button link={`/employer-branding`} className="text-white">
                          Learn more
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full md:block md:w-57">
                    <div className="w-36 md:w-auto">
                      <div className="w-19 md:h-40 md:w-25">
                        <SocialGood />
                      </div>
                    </div>
                    <div>
                      <h2 className="font-bold leading-tight text-white text-clamp-26-32">Social good storytelling</h2>
                      <div className="mt-clamp-5-8">
                        <Button link={`/social-good-storytelling/`} className="text-white">
                          Learn more
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full md:block md:w-57">
                    <div className="w-36 md:w-auto">
                      <div className="w-19 md:h-40 md:w-25">
                        <StrategyMarketing />
                      </div>
                    </div>
                    <div>
                      <h2 className="font-bold leading-tight text-white text-clamp-26-32">Strategy &amp; marketing.</h2>
                      <div className="mt-clamp-5-8">
                        <Button link="#strategy-marketing" className="text-white">
                          Learn more
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="lg:hidden">
        <Arrow colorTop="bg-white" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
      </div>

      <section className="pt-clamp-26-39 pb-clamp-42-79 lg:pt-0">
        <div className="relative overflow-hidden">
          <div className="container absolute inset-x-0 top-0 hidden lg:block">
            <div className="aspect-w-1436 aspect-h-100 extend after:bg-purple"></div>
            <Arrow colorTop="bg-white" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          </div>
          <div className="container flex">
            <div className="flex flex-col-reverse w-full grid-cols-12 md:grid gap-x-4 lg:grid-cols-11 lg:ml-auto lg:w-11/12">
              <div className="grid grid-cols-6 col-span-6 mt-20 md:mt-0 lg:flex lg:col-span-5 lg:pr-11">
                <div className="col-span-5 mt-20 sm:mt-12 lg:mt-auto">
                  <div className="hidden lg:block aspect-w-544 aspect-h-270"></div>
                  <h2 data-aos="fade-up" className="text-title">
                    Branding &amp; messaging.
                  </h2>
                  <div data-aos="fade-up">
                    <p className="mt-10">
                      We build brands that make deeper connections with the customers who matter most.
                    </p>
                    <ul className="mt-10 lg:mt-12">
                      <li>Brand strategy &amp; development</li>
                      <li>Messaging &amp; positioning</li>
                      <li>Visual identity</li>
                      <li>Research &amp; insights</li>
                      <li>Brand architecture &amp; naming</li>
                      <li>Launch &amp; measurement</li>
                      <li>Guidelines &amp; playbooks </li>
                    </ul>
                    <div className="mt-clamp-5-8">
                      <Button link={`/b2b-branding`} className="text-gray-darkest">
                        Learn more
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex col-span-6">
                <div className="w-full ml-auto lg:w-11/12">
                  <FeaturedWithDescription client="bpm" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container flex pt-clamp-19-31">
          <div className="grid-cols-12 md:grid gap-x-4">
            <div className="flex col-span-6">
              <div className="w-full mr-auto md:w-full lg:w-11/12">
                <FeaturedWithDescription client="marcos" />
              </div>
            </div>
            <div className="flex grid-cols-12 col-span-6 mt-20 md:mt-0 lg:col-span-5">
              <div className="mt-20 ml-auto sm:w-5/6 lg:w-11/12 sm:mt-12 lg:mt-auto">
                <div className="hidden lg:block aspect-w-544 aspect-h-270"></div>
                <h2 data-aos="fade-up" className="text-title">
                  Employee engagement.
                </h2>
                <div data-aos="fade-up">
                  <p className="mt-10">
                    We help you grab talent’s attention, keep them engaged, and leave them feeling inspired.
                  </p>
                  <ul className="mt-10 lg:mt-12">
                    <li>Employer brand development &amp; activation</li>
                    <li>Internal &amp; HR communications</li>
                    <li>Program branding &amp; communications</li>
                    <li>Recruitment marketing</li>
                    <li>Workplace branding</li>
                    <li>Employee &amp; stakeholder events</li>
                  </ul>
                  <div className="mt-clamp-8-12">
                    <Button link={`/employer-branding`} className="text-gray-darkest">
                      Learn more
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container flex pt-clamp-19-31">
          <div className="flex flex-col-reverse w-full grid-cols-12 md:grid gap-x-4 lg:grid-cols-11 lg:ml-auto lg:w-11/12">
            <div className="grid grid-cols-6 col-span-6 mt-20 md:mt-0 lg:flex lg:col-span-5 lg:pr-11">
              <div className="col-span-5 mt-20 sm:mt-12 lg:mt-auto">
                <div className="hidden lg:block aspect-w-544 aspect-h-270"></div>
                <h2 data-aos="fade-up" className="text-title">
                  Social good storytelling.
                </h2>
                <div data-aos="fade-up">
                  <p className="mt-10">We find new ways to showcase your progress and strengthen your impact.</p>
                  <ul className="mt-10 lg:mt-12">
                    <li>
                      <Link to="/sustainability" className="underline">
                        Sustainability branding
                      </Link>
                    </li>
                    <li>ESG, DEI &amp; CSR messaging development</li>
                    <li>Report structure, design &amp; presentation</li>
                    <li>Employee engagement campaigns</li>
                    <li>Communications strategy &amp; assets</li>
                    <li>Leadership alignment &amp; toolkits</li>
                  </ul>
                  <div className="mt-clamp-8-12">
                    <Button link={`/social-good-storytelling/`} className="text-gray-darkest">
                      Learn more
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex col-span-6">
              <div className="w-full ml-auto lg:w-11/12">
                <FeaturedWithDescription client="marcoscsr" />
              </div>
            </div>
          </div>
        </div>
        <div className="container flex pt-clamp-19-31">
          <div className="grid-cols-12 md:grid gap-x-4">
            <div className="flex col-span-6">
              <div className="w-full mr-auto md:w-full lg:w-11/12">
                <FeaturedWithDescription client="braen" />
              </div>
            </div>
            <div id="strategy-marketing" className="flex grid-cols-12 col-span-6 mt-20 md:mt-0 lg:col-span-5">
              <div className="mt-20 ml-auto sm:w-5/6 lg:w-11/12 sm:mt-12 lg:mt-auto">
                <div className="hidden lg:block aspect-w-544 aspect-h-270"></div>
                <h2 data-aos="fade-up" className="text-title">
                  Strategy &amp; marketing.
                </h2>
                <div data-aos="fade-up">
                  <p className="mt-10">Build a strong foundation for marketing success.</p>
                  <ul className="mt-10 lg:mt-12">
                    <li>Marketing strategy &amp; planning</li>
                    <li>Search engine marketing</li>
                    <li>Social media marketing</li>
                    <li>Analytics and reporting</li>
                    <li>UX &amp; website design</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      {/* SNAPSHOTS */}

      {/* <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <section className="relative overflow-hidden pt-clamp-16-80 bg-teal-light">
        <div className="relative">
          <div className="container relative">
            <div className="ml-auto lg:w-11/12">
              <div style={{ maxWidth: "700px" }}>
                <h2 data-aos="stagger" className="text-white text-title">
                  <span>Featured</span> <span>project</span> <span className="text-gray-dark">snapshots.</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="overflow-hidden mt-clamp-14-28">
            <div className="container">
              <div className="mx-auto lg:w-10/12">
                <CursorMarker>
                  <CursorMarker.Snapshot className="bg-purple after:text-white" />
                </CursorMarker>
                <Carousel>
                  <Carousel.Swiper centeredSlides={false}>
                    {Object.keys(snapShotData).map((snapshot, index) => {
                      return (
                        <Carousel.Slide key={index} style={{ width: "auto" }}>
                          <div data-aos="fade-up" data-aos-delay={200 * index}>
                            <Carousel.Snapshot client={snapshot}></Carousel.Snapshot>
                          </div>
                        </Carousel.Slide>
                      )
                    })}
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* OUR APPROACH */}

      {/* <section className="relative overflow-hidden pt-clamp-16-80 bg-teal-light">
        <div className="container relative flex flex-col">
          <div className="ml-auto lg:w-11/12">
            <div style={{ maxWidth: "455px" }}>
              <h2 data-aos="stagger" className="text-white text-title">
                <span>Our</span> <span>approach</span> <span className="text-gray-dark">puts</span>{" "}
                <span className="text-gray-dark">you</span> <span className="text-gray-dark">first.</span>
              </h2>
            </div>
            <div className="grid-cols-6 sm:grid lg:grid-cols-11">
              <div className="col-span-5 mt-10 lg:col-span-7">
                <p data-aos="fade-up">
                  We're guided by a single, shared storytelling philosophy—one that puts our clients first and follows a
                  simple set of rules.
                </p>
              </div>
            </div>
          </div>
          <div className="grid mx-auto mt-16 lg:w-10/12 sm:grid-cols-2 gap-x-4 gap-y-15">
            <div className="grid-cols-6 sm:grid lg:grid-cols-10">
              <div className="flex flex-col col-span-5 lg:col-span-9">
                <div data-aos="fade-up" className="mt-auto" id="our-approach-1">
                  <Lottie animation={funnelLottie} />
                </div>
                <p data-aos="fade-up" data-aos-anchor="#our-approach-1" className="mt-7">
                  <span className="font-bold">We make no assumptions.</span> Everything we do is grounded in
                  information, so you get an insightful, intelligent strategy and story.
                </p>
              </div>
            </div>
            <div className="grid-cols-6 sm:grid lg:grid-cols-10">
              <div className="flex flex-col col-span-5 lg:col-start-2 lg:col-span-9">
                <div data-aos="fade-up" data-aos-delay="100" className="mt-auto" id="our-approach-2">
                  <Lottie animation={handshakeLottie} />
                </div>
                <p data-aos="fade-up" data-aos-delay="100" data-aos-anchor="#our-approach-2" className="mt-7">
                  Whatever we build, design, develop, or dream up&mdash;
                  <span className="font-bold">we get there together.</span> We work with you to make sure your story is
                  authentic to your brand.
                </p>
              </div>
            </div>
            <div className="grid-cols-6 sm:grid lg:grid-cols-10">
              <div className="flex flex-col col-span-5 lg:col-span-9">
                <div data-aos="fade-up" className="mt-auto" id="our-approach-3">
                  <Lottie animation={repeatLottie} />
                </div>
                <p data-aos="fade-up" data-aos-anchor="#our-approach-3" className="mt-7">
                  <span className="font-bold">We always keep the end goal in mind.</span> We get excited about the
                  creative possibilities, but we always channel our enthusiasm to the ideas and designs that fuel your
                  success.
                </p>
              </div>
            </div>
            <div className="grid-cols-6 sm:grid lg:grid-cols-10">
              <div className="flex flex-col col-span-5 lg:col-start-2 lg:col-span-9">
                <div data-aos="fade-up" data-aos-delay="100" className="mt-auto" id="our-approach-4">
                  <Lottie animation={blockheartsLottie} />
                </div>
                <p data-aos="fade-up" data-aos-delay="100" data-aos-anchor="#our-approach-4" className="mt-7">
                  <span className="font-bold">We always look to exceed expectations.</span> We love nothing more than
                  engaging in unexpected ways that take our work—and the results we help to create—to the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Quote */}

      <section className="relative overflow-hidden bg-teal-light pt-clamp-25-41">
        <div className="container absolute inset-x-0 bottom-0">
          <div className="aspect-w-1436 aspect-h-165 before:bg-white-dark after:bg-white-dark extend"></div>
        </div>
        {/* <Arrow colorTop="bg-gray-darkest" colorBottom="bg-transparent" fillColor="text-transparent" /> */}
        <div className="container relative flex">
          <div className="w-full sm:w-10/12">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Default className="bg-white after:text-purple" />
              </CursorMarker>
              <Carousel.Swiper>
                <Carousel.Swiper>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay={200 * 0}>
                      <Carousel.Quote client="ans"></Carousel.Quote>
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay={200 * 1}>
                      <Carousel.Quote client="ad"></Carousel.Quote>
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay={200 * 2}>
                      <Carousel.Quote client="ricoh"></Carousel.Quote>
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay={200 * 3}>
                      <Carousel.Quote client="adp"></Carousel.Quote>
                    </div>
                  </Carousel.Slide>
                </Carousel.Swiper>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </div>
      </section>
      <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
    </Layout>
  )
}

export default WhatWeDoPage
